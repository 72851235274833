<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu has-demo v-on:demo="startDemo()"></q-my-stats-submenu>
        <!-- Charts-->
        <div class="mx-5">
            <v-card class="mx-auto ma-1 " color="" elevation="3" height="100%">
                <v-col cols="12" class="deep-purple pa-1">
                    <div class="q-display-3 pl-5 pt-3 white--text">
                        <v-row>
                            <v-col cols="6">
                                Event Leaderboards
                            </v-col>
                            <!-- <v-col id="q-leaderboard-step-1" cols="6" class="text-left">
                                <q-business-time-period-select :disabled="loading" :dark="true" v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat"></q-business-time-period-select>
                            </v-col> -->
                        </v-row>
                    </div>
                </v-col>
                <QTabs dark>
                    <v-tab v-if="user.Agent.HasManagerAccess || role == 'SuperAdmin'">Baseshop </v-tab>
                    <v-tab v-if="user.Agent.IsSFGDirect || role == 'SuperAdmin'">Direct Agency</v-tab>
                    <v-tab v-if="user.Agent.LeadershipLevel == 'Key Leader' || role == 'SuperAdmin'">Key Leader</v-tab>
                    <v-tab v-if="user.Agent.IsAgencyOwner || role == 'SuperAdmin'">Agency Owner</v-tab>
                    <v-tab v-if="user.Agent.LeadershipLevel == 'Agency Director' || role == 'SuperAdmin'">Agency Director</v-tab>
                    <v-tab v-if="user.Agent.LeadershipLevel == 'Team Leader' || role == 'SuperAdmin'">Team Leader</v-tab>
                    <v-tab-item v-if="user.Agent.HasManagerAccess || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="overallbaseshop"
                            :agent-code="user.AgentCode"
                            title="Overall Baseshop Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.Agent.IsSFGDirect || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="overalldirect"
                            :agent-code="user.AgentCode"
                            title="Overall Direct Agency Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.Agent.LeadershipLevel == 'Key Leader' || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="keyleader"
                            :agent-code="user.AgentCode"
                            title="Key Leader Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.Agent.IsAgencyOwner || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="agencyowner"
                            :agent-code="user.AgentCode"
                            title="Agency Owner Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.Agent.LeadershipLevel == 'Agency Director' || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="agencydirector"
                            :agent-code="user.AgentCode"
                            title="Agency Director Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.Agent.LeadershipLevel == 'Team Leader' || role == 'SuperAdmin'">
                        <q-events-leaderboard-data-table
                            type="teamleader"
                            :agent-code="user.AgentCode"
                            title="Team Leader Promoter"
                            :rows="25"
                        >
                        </q-events-leaderboard-data-table>
                    </v-tab-item>
                </QTabs>
            </v-card>
        </div>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QEventsLeaderboardDataTable from '@/components/datatables/QEventsLeaderboardDataTable.vue'
import QTabs from '../../components/navigation/QTabs.vue'

export default {
    data() {
        return {
            datePeriod: { label: 'Current Month', 'period': 'month' },
        }
    },

    computed: {
        'time_period': function() {
            if (this.datePeriod.period == 'week') {
                return 'weekly'
            }
            if (this.datePeriod.period == 'month') {
                return 'monthly'
            }
            if (this.datePeriod.period == 'year') {
                return 'yearly'
            }
        }
    },
    methods: {
        'startDemo': function() {
            this.$tours['DemoLeaderboards'].start()
        },
    },

    components: {
        QEventsLeaderboardDataTable,
        QMyStatsSubmenu,
        QTabs,
    }
}

</script>
